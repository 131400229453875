<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>PAR GOL</li>
            </ul>
            <h2>Programma “Garanzia di Occupabilità dei Lavoratori — GOL”</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<div class="courses-details-area pb-100">
    <div class="courses-details-image">
        <img src="assets/img/pargol.jpg" style="max-height:496px; width:100%; object-fit: cover;  object-position: bottom bottom;" alt="image">
    </div>
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="courses-details-desc">
                    <ngx-tabset>
                        <ngx-tab tabTitle="PAR GOL">
                            <div class="courses-overview">
                                <h3>CHE COS’É IL PROGRAMMA GOL</h3>
                                <p>
                                    Il Programma nazionale “Garanzia Occupabilità Lavoratori” (2021-2025) è il perno dell’azione di riforma delle politiche attive del lavoro previsto nell’ambito del Piano Nazionale di Ripresa e Resilienza, Missione 5 “Inclusione e coesione”, Componente 1 “Politiche attive del lavoro e sostegno all’occupazione”, Riforma 1.1 “Politiche attive del lavoro e formazione” finanziato dall’Unione europea – Next Generation EU. Attuato dalle singole Regioni, sulla base dei Piani regionali (Par) approvati da Anpal, il Programma Gol tiene conto di un’analisi precisa e puntuale del contesto nel quale opera.
                                </p>
                                <p>
                                    L’andamento del mercato del lavoro territoriale tiene conto di fattori demografici, di struttura e dinamiche del mercato del lavoro, della popolazione in cerca di lavoro e popolazione non attiva, e degli interventi di sostegno al reddito. Gol ridisegna i servizi per il lavoro per migliorare l’inserimento lavorativo delle persone e prevede percorsi di accompagnamento al lavoro, di aggiornamento o riqualificazione professionale, e percorsi in rete con gli altri servizi territoriali (sociali, socio-sanitari, di conciliazione, educativi) nel caso di bisogni complessi, quali quelli di persone con disabilità o con fragilità. Grazie a un orientamento di base più mirato, attraverso un’attenta valutazione dell’occupabilità (assessment) la persona è indirizzata al percorso più adeguato.
                                </p>

                                <h3>A CHI SI RIVOLGE IL PROGRAMMA GOL
                                </h3>
                                <p>
                                    Sono Beneficiari del programma le persone dai 30 fino a 65 anni, residenti e/o domiciliate in Campania, che sono, alternativamente in almeno una delle seguenti situazioni:
                                </p>
                                <ul>
                                    <li>Beneficiari di ammortizzatori sociali in costanza di rapporto di lavoro: le specifiche categorie di lavoratori sono individuate dalla Legge di Bilancio 2022;</li>
                                    <li>Beneficiari di ammortizzatori sociali in assenza di rapporto di lavoro: disoccupati percettori di NASPI o DIS-COLL;</li>
                                    <li>Beneficiari di sostegno al reddito di natura assistenziale: percettori del Reddito di cittadinanza;</li>
                                    <li>Lavoratori fragili o vulnerabili: donne in condizioni di svantaggio, persone con disabilità, lavoratori maturi (55 anni e oltre), persone che sono in carico o sono segnalate dagli enti che operano nel sistema dei servizi sociali o sociosanitari e/o sono inserite in progetti/interventi di inclusione sociale;</li>
                                    <li>Disoccupati senza sostegno al reddito: disoccupati da almeno sei mesi, altri lavoratori con minori opportunità occupazionali (giovani e donne, anche non in condizioni di fragilità), lavoratori autonomi che cessano l’attività o con redditi molto bassi;</li>
                                    <li>Lavoratori con redditi molto bassi (i cosiddetti working poor): il cui reddito da lavoro dipendente o autonomo sia inferiore alla soglia dell’incapienza secondo la disciplina fiscale.</li>
                                </ul>

                                <h3>COME ISCRIVERSI AL PROGRAMMA GOL</h3>
                                <p>
                                    L’accesso al programma dei beneficiari avviene a seguito di convocazione e/o invito all’adesione da parte degli operatori dei Centri per l’Impiego della Campania. Gli operatori dei Centri per l’Impiego della Campania convocano mediante sms/mail i cittadini che hanno attiva una Dichiarazione di Immediata Disponibilità e quelli che la presentano dopo la pubblicazione del presente Avviso, nonché i cittadini che hanno presentato domanda per il Reddito di Cittadinanza, dando priorità alle donne, ai disoccupati di lunga durata, alle persone con disabilità, ai lavoratori over 55.                                 </p>
                                <p>
                                    Gli operatori dei Centri per l’Impiego convocano, altresì, i Beneficiari di ammortizzatori sociali in costanza di rapporto di lavoro, ma potenzialmente in transizione per effetto di crisi aziendali, con particolare riferimento alle attività previste nell’ambito del Percorso 5 – “Ricollocazione collettiva”.
                                </p>
                                <p>
                                    I beneficiari convocati/invitati dagli operatori dei Centri per l’Impiego della Campania sono tenuti a riscontrare la convocazione/invito ricevuto secondo quanto ivi descritto, compreso l’eventuale accesso tramite SPID/CIE – per il tramite del portale SILF Campania (https://lavoro.regione.campania.it/) – agli strumenti digitali predisposti per il pre-assessment e l’indicazione della propria preferenza circa lo svolgimento (in presenza o da remoto) degli appuntamenti successivi. I beneficiari che optano per lo svolgimento in presenza degli appuntamenti successivi vengono convocati dagli operatori dei Centri per l’Impiego della Campania mediante sms/mail indicanti luogo, data ed ora dell’appuntamento. I beneficiari che optano per lo svolgimento da remoto degli appuntamenti successivi vengono convocati dagli operatori dei Centri per l’Impiego della Campania convocano mediante mail indicante data, ora e modalità di svolgimento del colloquio (VOIP o VIDEOCALL).
                                </p>
                            </div>
                            <div class="btn-box mt-5">
                                <a routerLink="/contatti" class="default-btn"><i class="flaticon-tag"></i>Richiedi informazioni<span></span></a>
                            </div>
                        </ngx-tab>
                    </ngx-tabset>
                </div>
            </div>
            <div class="col-lg-4 col-md-12 d-none">
                <div class="courses-details-info">
                    <div class="image">
                        <img src="assets/img/courses/img1.jpg" alt="image">
                        <a href="https://www.youtube.com/watch?v=PWvPbGWVRrU" class="link-btn popup-youtube"></a>
                        <div class="content">
                            <i class="flaticon-play"></i>
                            <span>Course Preview</span>
                        </div>
                    </div>
                    <ul class="info">
                        <li class="price">
                            <div class="d-flex justify-content-between align-items-center">
                                <span><i class="flaticon-tag"></i> Price</span>
                                $49
                            </div>
                        </li>
                        <li>
                            <div class="d-flex justify-content-between align-items-center">
                                <span><i class="flaticon-teacher"></i> Instructor</span>
                                Sarah Taylor
                            </div>
                        </li>
                        <li>
                            <div class="d-flex justify-content-between align-items-center">
                                <span><i class="flaticon-time"></i> Duration</span>
                                7 weeks
                            </div>
                        </li>
                        <li>
                            <div class="d-flex justify-content-between align-items-center">
                                <span><i class="flaticon-distance-learning"></i> Lessons</span>
                                25
                            </div>
                        </li>
                        <li>
                            <div class="d-flex justify-content-between align-items-center">
                                <span><i class="flaticon-web"></i> Enrolled</span>
                                255 students
                            </div>
                        </li>
                        <li>
                            <div class="d-flex justify-content-between align-items-center">
                                <span><i class="flaticon-lock"></i> Access</span>
                                Lifetime
                            </div>
                        </li>
                    </ul>
                    <div class="btn-box">
                        <a routerLink="/contatti" class="default-btn"><i class="flaticon-tag"></i>Richiedi informazioni<span></span></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="courses-area bg-f8f9f8 pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Altri corsi che potrebbero piacerti</h2>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6" *ngFor="let propostaCorso of nPropostaCorsi">
                <div class="single-courses-box">
                    <div class="courses-image">
                        <a target="_parent" href="/dettaglio-corso/{{propostaCorso['corso']['tag']}}" class="d-block image">
                            <img *ngIf="propostaCorso['corso']['isMiur']==1" src="assets/img/miur.png" style="height: 50px; position: absolute; bottom: 20px; left: 25px;">
                            <img src="{{urlImage+propostaCorso['corso']['image']}}" alt="image">
                        </a>
                    </div>
                    <div class="courses-content">
                        <h3><a target="_parent" href="/dettaglio-corso/{{propostaCorso['corso']['tag']}}">{{propostaCorso['corso']['titolo']}}</a></h3>
                        <p style="overflow: hidden;
                                  text-overflow: ellipsis;
                                  display: -webkit-box;
                                  -webkit-line-clamp: 3;
                                  -webkit-box-orient: vertical">{{propostaCorso['corso']['descrizione']}}</p>
                        <ul *ngIf="propostaCorso['corso']['durata'].durata_numero>0" class="courses-box-footer d-flex justify-content-between align-items-center">
                            <li class="fw-bold"><i class='flaticon-time-left'></i>
                                {{propostaCorso['corso']['durata'].durata_numero}}{{propostaCorso['corso']['durata'].durata_label}}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

